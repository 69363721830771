import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductPageContainer as SourceProductPageContainer,
} from 'SourceRoute/ProductPage/ProductPage.container';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace AlmusbahblendPwa/Route/ProductPage/Container */
export class ProductPageContainer extends SourceProductPageContainer {
    // TODO implement logic
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPageContainer);
